<template>
    <div>
        <Catalog/>
    </div>
</template>


<script>
import Catalog from '../components/Catalog.vue'
export default {
    components:{
        Catalog
    }
}
</script>


<style lang='scss' scoped>
.header {
    height: 300px;
    text-align: center;
    background-color: #d8d8d8;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    &__text{
        color: white;
        font-size: 40px;
    }
}
.sidebar {
    display: flex;
    justify-content: center;
    align-items: flxe-start;
    background-color: #eaeaea;
    width: 100%;

    &__text{
        color: white;
        font-size: 40px;
    }
}
.sidebar--wrapper{
    display: flex;

    @media (max-width: 1200px){
        order: 2;
    }
}
</style>
