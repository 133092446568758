<template>
	<div class="catalog" @mouseout="setHoveredCategory('')">

        <!-- BEGIN схема со скелетом слева -->
		<div class="catalog__pics">
			<div class="catalog__pics-bg">
				<div
                    v-for="category in categories"
                    :key="category.wp_id"
					:id="category.html_id"
					class="catalog__pics-item"
                    :class="{'catalog__pics-item--hover': category.wp_id === categoryHovered}"
                    @mouseover="setHoveredCategory(category.wp_id)"
                    @click="setActiveCategoryOnSheme(category.wp_id)"
				>
                </div>
			</div>
		</div>
        <!-- END схема со скелетом слева -->

		<div class="catalog__list">
            <!-- BEGIN Список категорий верхнего уровня -->
            <template v-if="categoryActive === ''">
                <div 
                    class="catalog__list-item catalog__list-item--category"
                    v-for="category in wpTopLevelCategories"
                    :key="category.id"
                    :class="{'catalog__list-item--hover': category.id === categoryHovered}"
                    @mouseover="setHoveredCategory(category.id)"
                    @click="categoryActive = category.id"
                >
                    {{category.title}}
                </div>
            </template>
            <!-- END Список категорий  верхнего уровня-->

            <!-- BEGIN Список постов категории -->
            <template v-if="categoryActive">
                <!-- BEGIN Товары в главной категории -->
                <div
                    class="catalog__category-title"
                >
                    {{activeCategoryTitle}}
                </div>

                <div 
                    class="catalog__back-btn"
                    @click="categoryActive = ''"
                >
                    <i class="fa fa-chevron-left" aria-hidden="true"></i> назад
                </div>
                
                <a 
                    class="catalog__list-item catalog__list-item--product"
                    v-for="post in categoryPosts"
                    :key="post.id"
                    :href="post.link"
                >
                    <img :src="post.img ? post.img : 'https://via.placeholder.com/300?text=НЕТ%20ФОТО'">
                    <div class="descr">
                        {{post.title}}
                    </div>
                </a>
                <!-- END Товары в главной категории -->

                <!-- BEGIN Товары в подкатегориях если они есть -->
                <template v-if="wpCategoriesHierarhy[categoryActive]"><!-- Проверить есть ли потомки категории по хеш-таблице   -->
                    <div v-for="(subcategory, index) in subcategoriesData" :key="index">

                        <div class="catalog__subcategory-title">{{subcategory.title}}</div>
                        <!-- BEGIN товары подкатегории -->
                        <a 
                            class="catalog__list-item catalog__list-item--product"
                            v-for="post in subcategory.products"
                            :key="post.id"
                            :href="post.link"
                        >
                            <img :src="post.img ? post.img : 'https://via.placeholder.com/300?text=НЕТ%20ФОТО'">
                            <div class="descr">
                                {{post.title}}
                            </div>
                        </a>                        
                        <!-- END товары подкатегории -->

                    </div>
                </template>
                <!-- END Товары в подкатегориях если они есть -->

            </template>
            <!-- END Список постов категории  -->

		</div>
	</div>
</template>

<script>
import axios from 'axios';
// import api from '../helpers/api.js';

export default {
    data: () => ({
        // категории на схеме - по html_id рендерится #id и позционируются элементы, по wp_id увязка с категориями wp
        categories:[
            { html_id: 'scull', wp_id: 3, title: 'Scull'},
            { html_id: 'spine', wp_id: 4, title: 'Spine'},
            { html_id: 'Clavicle', title: 'Clavicle', wp_id: 5},
            { html_id: 'ProximalHumerus', title: 'Proximal Humerus', wp_id: 6},
            { html_id: 'HumeralShaft', title: 'Humeral Shaft', wp_id: 7},
            { html_id: 'DistalHumerus', title: 'Distal Humerus', wp_id: 8},
            { html_id: 'ProximalRadius', title: 'Proximal Radius', wp_id: 9},
            { html_id: 'ProximalUlna', title: 'Proximal Ulna', wp_id: 10},
            { html_id: 'Radial_UlnarShaft', title: 'Radial Ulnar Shaft', wp_id: 11},
            { html_id: 'DistalRadius', title: 'Distal Radius', wp_id: 12},
            { html_id: 'Hand', title: 'Hand', wp_id: 13},
            { html_id: 'Pelvis', title: 'Pelvis', wp_id: 14},
            { html_id: 'ProximalFemur', title: 'Proximal Femur', wp_id: 15},
            { html_id: 'FemoralShaft', title: 'Femoral Shaft', wp_id: 16},
            { html_id: 'DistalFemur', title: 'Distal Femur', wp_id: 17},
            { html_id: 'Patella', title: 'Patella', wp_id: 18},
            { html_id: 'LateralProximalTibia', title: 'Lateral Proximal Tibia', wp_id: 19},
            { html_id: 'MedialProximalTibia', title: 'Medial Proximal Tibia', wp_id: 20},
            { html_id: 'TibialShaft', title: 'Tibial Shaft', wp_id: 21},
            { html_id: 'DistalTibia', title: 'Distal Tibia', wp_id: 22},
            { html_id: 'Fibula', title: 'Fibula', wp_id: 23},
            { html_id: 'Calcaneus', title: 'Calcaneus', wp_id: 24},
            { html_id: 'Foot', title: 'Foot', wp_id: 25},
        ],
        categoryHovered: '',  // id категории для подсветки
        categoryActive: '', // id активной категории (открытый раздел каталога)
        wpCategories: [], 
        products: [],  // продукты загруженные из wp
    }),

    computed:{
        /** категории из wp для списка категорий справа */
        wpTopLevelCategories(){            
            let res = this.wpCategories
                .filter(category => category.parent === 0) // получить категории у которых нет родителя
                .filter(category => category.id !== 1)    // фильтровать Uncategorized
            return res;
        },

        /** товары из активной категории */
        categoryPosts(){
            const categoryProducts = this.products.filter(product => product.categories.some(category => category === this.categoryActive));
            // console.log( categoryProducts ); 
            return categoryProducts;
        },

        /** заголовок активной категории (т.е. открытого раздела каталога) */
        activeCategoryTitle(){
            if(this.categoryActive === ''){
                return '';
            }
            const activeCategoryDataObj = this.wpCategories.find(category => category.id === this.categoryActive);
            return activeCategoryDataObj.title
        },

        /** иехрахия категорий по родители-потомки в виде хеш таблицы  { родитель: [потомок1, потомок2] } - для получения данных подкатегорий subcategoriesData*/
        wpCategoriesHierarhy(){
            const wpCategories = this.wpCategories
            const categoriesHierarchyHeshTable = {}

            
            wpCategories.map(wpItem=>{
                // если у категории есть родитель
                if(wpItem.parent !== 0){
                    let parentId = wpItem.parent;
                    let childId = wpItem.id;
                    // если этого родителя встречаем первый раз - создать поле хеш таблицы
                    if (!categoriesHierarchyHeshTable[parentId]){
                        categoriesHierarchyHeshTable[parentId] = [childId]
                    }
                    // если этот родитель уже есть - добавить ему потомков
                    else{
                        categoriesHierarchyHeshTable[parentId].push(childId)
                    }
                }    
            })
            
            return categoriesHierarchyHeshTable
        },

        /** подкатегории и товары для активной категории */
        subcategoriesData(){
            const wpCategoriesHierarhy = this.wpCategoriesHierarhy;
            const categoryActive = this.categoryActive;
            // const categoryActive = 3;
            const res = []

            // если у активной категории есть подкатегории
            if( wpCategoriesHierarhy[categoryActive] ){
                for(let subcategoryId of wpCategoriesHierarhy[categoryActive]){
                    let subcategoryName = this.wpCategories.find(category => category.id === subcategoryId).title;
                    let subcategoryProducts = this.products.filter(product => product.categories.some( categoryId => categoryId === subcategoryId))

                    let subcategoryData = {
                        title: subcategoryName,
                        products: subcategoryProducts
                    }

                    // выводить подкатегорию только если в ней есть товары
                    if(subcategoryProducts.length > 0){
                        res.push(subcategoryData)
                    }

                }
            }

            return res;
        }
    },

    methods:{
        setHoveredCategory(wpId){
            // включать "ховер" эффект только если это главная, не список товаров категории
            if(this.categoryActive === ''){
                this.categoryHovered = wpId;
            }
        },

        setActiveCategoryOnSheme(wpId){
            // поменять активную категорию только если она не задана - т.е. не мы не в разделе каталога
            if(this.categoryActive === ''){
                this.categoryActive = wpId;
            }
        },


    },

    async created(){
        this.wpCategories = await api().getCategories();
        this.products = await api().getProducts();
    }

};


/************************************
Api "сервис"
*************************************/

function api(){
    return{
        async getProducts(){
            // wp выдает ответ до 100 шт максиум - чтоб получить любое количество товаров:

            // узнать количество страниц в ответе api
            const apiPages = await axios
                .head('http://interlok.com.ua/wp-json/wp/v2/products?per_page=100')
                .then(r => r.headers)
                .then(headers => headers['x-wp-totalpages'])
                .catch(err => handleError(err, 'api().getProducts()'));
            console.log( apiPages ); 


            // сделать паралельные запросы по количеству страниц
            const promises = [];
            for(let i = 1; i<= apiPages; i++){
                promises.push( axios.get(`http://interlok.com.ua/wp-json/wp/v2/products?per_page=100&page=${i}`) )
            }
            
            return axios.all(promises)
                .then(responses =>{
                    // объединить результаты запросов
                    let result = []
                    for (let response of responses){
                        result = [...result, ...response.data];
                    }
                    return result
                })
                .then(wpData => {
                    const res = wpData.map(wpItem=>{
                        return {
                            title: wpItem.title.rendered,
                            id: wpItem.id,
                            link: wpItem.link,
                            categories: wpItem.categories,
                            img:(wpItem.thumbnails.medium) ? wpItem.thumbnails.medium[0] : ''
                        }
                    })
                    return res;
                })
                .catch(err => handleError(err, 'api().getProducts()'))    
        },


        getCategories(){
            return axios
                .get('http://interlok.com.ua/wp-json/wp/v2/categories?per_page=100')
                .then(resp => resp.data)
                .then(wpData => {
                    const res = wpData.map(wpItem=>{
                        return {
                            title: wpItem.name,
                            id: wpItem.id,
                            parent: wpItem.parent
                        }
                    })
                    return res;
                })
                .catch(err => handleError(err, 'api().getCategories()'))
        }
    }
}

function handleError(err, apiMethod){
    console.error(`Error in api method ${apiMethod}:`);
    console.error(err);
    throw err; // на случай если нужно будет добавить лоадер
}


</script>

<style lang="scss" scoped src="./Catalog.scss"></style>

